import repeatFunctionUntilTrue from '../utils/repeatFnUntilTrue';
const BREAKPOINT = {
    MOBILE: 768,
    DESKTOP: 769,
};
const calculateOuterWidth = (el) => {
    const style = getComputedStyle(el);
    return el.offsetWidth + parseInt(style.marginLeft, 10) + parseInt(style.marginRight, 10);
};
const calculateSpaceUsed = (items) => items.reduce((total, el) => total + calculateOuterWidth(el), 0);
const calculateAvailableSpace = (el) => {
    var _a;
    const minusWidth = parseInt((_a = el.dataset.minusavailabelwidth) !== null && _a !== void 0 ? _a : '60', 10);
    return calculateOuterWidth(el) - minusWidth;
};
class NavigationManager {
    constructor(root) {
        var _a, _b;
        this.root = root;
        this.resizeObserver = null;
        this.rootList = root.querySelector('.js-navMore__root');
        this.moreListParent = (_b = (_a = this.rootList) === null || _a === void 0 ? void 0 : _a.querySelector('.more')) !== null && _b !== void 0 ? _b : null;
        this.rebuildMenu = this.createMenuRebuildFunction();
    }
    detectView() {
        return window.innerWidth < BREAKPOINT.DESKTOP ? 'Mobile' : 'Desktop';
    }
    shouldInitializeDesktopNav() {
        const isDesktopNav = this.root.classList.contains('js-navMoreDesktop');
        const isDesktopView = this.detectView() === 'Desktop';
        return !isDesktopNav || (isDesktopNav && isDesktopView);
    }
    createMenuRebuildFunction() {
        if (!this.moreListParent)
            return () => { };
        const moreList = this.moreListParent.querySelector('ul');
        if (!moreList)
            return () => { };
        return () => {
            var _a, _b, _c, _d;
            const rootItems = this.root.querySelectorAll('.js-navMore__root > li');
            const rootItemsWidth = calculateSpaceUsed(Array.from(rootItems));
            const availableSpace = calculateAvailableSpace(this.root);
            if (rootItemsWidth > availableSpace) {
                const rootListLastChild = rootItems[rootItems.length - 2];
                if (rootListLastChild) {
                    rootListLastChild.setAttribute('data-width', calculateOuterWidth(rootListLastChild).toString());
                    moreList.insertBefore(rootListLastChild, moreList.firstChild);
                    this.rebuildMenu();
                }
            }
            else {
                const moreListFirstChild = (_a = this.moreListParent) === null || _a === void 0 ? void 0 : _a.querySelectorAll('li')[0];
                if (moreListFirstChild &&
                    rootItemsWidth + parseInt((_b = moreListFirstChild.dataset.width) !== null && _b !== void 0 ? _b : '0', 10) < availableSpace) {
                    (_c = this.rootList) === null || _c === void 0 ? void 0 : _c.insertBefore(moreListFirstChild, this.moreListParent);
                }
            }
            (_d = this.moreListParent) === null || _d === void 0 ? void 0 : _d.classList.toggle('hidden', !this.moreListParent.querySelectorAll('li').length);
        };
    }
    setupDropdowns() {
        const showDropdownLinks = this.root.querySelectorAll('.showDropdown');
        if (!showDropdownLinks.length)
            return;
        Array.from(showDropdownLinks).forEach((item) => {
            item.addEventListener('click', function () {
                const parent = this.parentElement;
                if (parent instanceof HTMLElement) {
                    parent.classList.toggle('on');
                }
            });
        });
        document.addEventListener('click', (event) => {
            const isClickInside = this.root.contains(event.target);
            if (!isClickInside) {
                Array.from(showDropdownLinks).forEach((link) => {
                    const parent = link.parentElement;
                    if (parent instanceof HTMLElement) {
                        parent.classList.remove('on');
                    }
                });
            }
        });
    }
    renderDesktopView() {
        this.root.style.overflow = '';
        if (this.moreListParent) {
            this.moreListParent.classList.remove('hidden');
            const handleResize = this.root.classList.contains('liveNowTop')
                ? () => {
                    const container = document.getElementById('container');
                    if (container && calculateOuterWidth(container) > calculateOuterWidth(this.root)) {
                        return;
                    }
                    this.rebuildMenu();
                }
                : () => this.rebuildMenu();
            this.rebuildMenu();
            this.resizeObserver = new ResizeObserver(handleResize);
            this.resizeObserver.observe(this.root);
        }
    }
    renderMobileView() {
        if (!this.moreListParent || !this.rootList)
            return;
        const moreList = this.moreListParent.querySelector('ul');
        if (!moreList)
            return;
        const moreItems = Array.from(moreList.querySelectorAll('li'));
        moreItems.forEach(item => {
            var _a;
            (_a = this.rootList) === null || _a === void 0 ? void 0 : _a.insertBefore(item, this.moreListParent);
        });
        this.moreListParent.classList.add('hidden');
        this.root.style.overflow = 'auto';
    }
    cleanup() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
            this.resizeObserver = null;
        }
    }
    updateView() {
        this.cleanup();
        const view = this.detectView();
        if (view === 'Desktop' && this.shouldInitializeDesktopNav()) {
            this.renderDesktopView();
        }
        else {
            this.renderMobileView();
        }
    }
    init() {
        this.updateView();
        this.setupDropdowns();
        window.addEventListener('resize', () => this.updateView());
    }
}
export default function navigationMoreInit() {
    repeatFunctionUntilTrue(() => {
        const navigationRoots = document.querySelectorAll('.js-navMore:not([data-jsinit])');
        if (!navigationRoots.length || typeof ResizeObserver !== 'function') {
            return false;
        }
        Array.from(navigationRoots).forEach((root) => {
            if (root.dataset.jsinit)
                return;
            root.dataset.jsinit = 'true';
            const manager = new NavigationManager(root);
            manager.init();
        });
        return true;
    });
}
